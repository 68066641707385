import { Client as Appwrite, Databases, Account } from 'appwrite';
const collection = process.env.VUE_APP_APPWRITE_COLLECTION
const db = process.env.VUE_APP_APPWRITE_DATABASE
const api = {
    sdk: null,

    provider: () => {
        if (api.sdk) {
            return api.sdk;
        }
        let appwrite = new Appwrite();
        appwrite.setEndpoint(process.env.VUE_APP_APPWRITE_ENDPOINT).setProject(process.env.VUE_APP_APPWRITE_PROJECT)
        const database = new Databases(appwrite);
        const account = new Account(appwrite);
        api.sdk = { database, account };
        return api.sdk
    },
    getDocumentList() {
        return api.provider().database.listDocuments(db,collection);
        
    },
    addDocument(content) {
        return api.provider().database.createDocument(db,collection, "unique()", content)
    },
    getDocument(id) {
        return api.provider().database.getDocument(db,collection, id);
    },
    updateDocument(id, content) {
        return api.provider().database.updateDocument(db,collection, id, content);
    },
    deleteDocument(id) {
        return api.provider().database.deleteDocument(db,collection, id);
    },
    login(email, password) {
        return api.provider().account.createEmailSession(email, password);
    },
    logout(id) {
        return api.provider().account.deleteSession(id);
    },
    checkSession() {
        return api.provider().account.get();
        
    },
    updateName(name) {
        return api.provider().account.updateName(name);
    },
    updateEmail(email) {
        return api.provider().account.updateEmail(email);
    },
    updatePassword(password, oldPassword) {
        return api.provider().account.updatePassword(password, oldPassword);
    },
}

export default api;
<template>
  <n-theme-editor>
    <n-config-provider :theme-overrides="theme">
      <n-message-provider>
      <nav>
        <router-link to="/"
          ><n-button :focusable="false" text>Home</n-button></router-link
        >
        <router-link to="/test-page"
          ><n-button :focusable="false" text>Test</n-button></router-link
        >

        <n-popover trigger="click" placement="bottom-end">
          <template #trigger
            ><n-button :focusable="false" text>Account</n-button></template
          >
          <div v-if="!checkAuth" style="width: 300px; padding: 5px;">
            <n-form ref="formRef" :model="loginForm" label="Log In" :rules="rules">
              <n-form-item path="email" label="Email"
                ><n-input
                  v-model:value="loginForm.email"
                  type="text"
                  placeholder="Email"
              /></n-form-item>
              <n-form-item path="password" label="Password"
                ><n-input
                  v-model:value="loginForm.password"
                  type="password"
                  placeholder="Password"
              /></n-form-item>
              <n-button @click="validateSend" :focusable="false">Log In</n-button>
            </n-form>
          </div>
          <div v-else class="secondaryMenu" style="width: 150px; display: flex; flex-direction: column; gap: 10px; padding: 5px; text-align: center;">
            <router-link v-if="checkAuth" to="/admin"
          ><n-button :focusable="false" text>Dashboard</n-button></router-link
        >
        <router-link v-if="checkAuth" to="/account"
          ><n-button :focusable="false" text>Settings</n-button></router-link
        >
            <n-button @click="logout" :focusable="false">Log Out</n-button>
          </div>
        </n-popover>
      </nav>
      <router-view />
    </n-message-provider>
    </n-config-provider>
  </n-theme-editor>
</template>

<script setup>
import { routes } from "./router";
import { computed, ref } from "vue";
import {
  NButton,
  NConfigProvider,
  NThemeEditor,
  NPopover,
  NInput,
  NForm,
  NFormItem,
  NMessageProvider,
} from "naive-ui";
import { useAuthStore } from "./store/authStore";
import theme from "@/assets/theme";
const auth = useAuthStore();
auth.get();
const formRef = ref(null);
const checkAuth = computed(() => {
  if (Object.keys(auth.user).length == 0) {
    return false;
  } else {
    return true;
  }
});



const loginForm = ref({
  email: "",
  password: "",
});

const validateSend = () => {
  const email = loginForm.value.email;
  const password = loginForm.value.password;
  console.log(email)
  formRef.value.validate((errors) => {
    if (!errors) {
      //validate success
      loginForm.value.password ="";
      loginForm.value.email = "";
      auth.loginUser(email, password);
    } else {
      //validate fail
      console.log("Login Error");
    }
  });
};

const logout = () => {
  auth.logoutUser();
};

const rules = {
  email: [
    {
      required: true,
      validator(rule, value) {
        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
          return new Error("Please enter a valid email address");
        }
        return true;
      },
      trigger: ["input", "blur"],
    },
  ],
  password: [
    {
      required: true,
    },
  ],
};

const loginButton = computed(() => {
  let val = formRef.validate();
  console.log(val);
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
body {
  background-color: #283252;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fffaf2;
}

nav {
  padding: 30px;
  display: flex;
  justify-content: right;
  gap: 30px;
}

nav a {
  text-decoration: none;
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style scoped>
.secondaryMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  text-align: center;
}
.secondaryMenu a {
  text-decoration: none;
  font-weight: bold;
}
</style>

<template>
  <div class="home">
    <div class="centeredFlex">
    <div class="contentBox">
      <h1>The Python Test</h1>
      <h2>Made by developers, for developers...</h2>
    </div>
    </div>
    <div style="height: 20vh"></div>
    <h1>Take the test now</h1>
    <n-button size="large" @click="goToTest" :focusable="false">Enter</n-button>
  </div>
</template>

<script setup>
import { NButton } from "naive-ui";
import router from "@/router";

const goToTest = () => {
  router.push({path: "/test-page"})
}
</script>

<style scoped>
.centeredFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: left;
  gap: 30px;
  -webkit-justify-content: center;
  justify-content: space-around;
}
.contentBox {
  text-align: left;
  font-family: 'Archivo Black', arial;
}
h1 {
  font-family: 'Archivo Black', arial;
}
.contentBox h1, h2 {
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}
.contentBox h1 {
  font-size: 5rem;
}
.contentBox h2 {
  font-size: 2rem;
}
</style>
import { defineStore } from 'pinia'
import api from '../api'

export const useAuthStore = defineStore('auth', {
    state: () => { 
        return {
        user: {},
        isAuth: false
        }
     },
    actions: {
        async loginUser(email, password) {
            const session = await api.login(email, password)
            this.get()
        },
        async logoutUser() {
            await api.logout('current')
            this.assignSession({})
        },
        assignSession(session) {
            this.user = session
            this.isAuth = session.status
        },
        async get() {
            try {
                const account = await api.checkSession()
                if (account) {
                    this.assignSession(account)
                }
            } catch (error) {
                this.assignSession({})
                console.log("No Account Session")
            }
        },
        async updateName(name) {
            await api.updateName(name)
            this.get()
        },
        async updateEmail(email) {
            await api.updateEmail(email)
            this.get()
        },
        async updatePassword(password, oldPassword) {
            await api.updatePassword(password, oldPassword)
            this.get()
        },
    }
})
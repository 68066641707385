export default {
    Button: {
        textColorText: '#FFFAF2',
        textColorTextHover: '#939393FF',
        textColor: '#FFFAF2',
        textColorHover: '#939393FF',
        border: '1px solid #FFFAF2',
        borderHover: '1px solid #939393FF',
        rippleColor: '#D5D5D5FF',
        textColorPressed: '#EAEAEAFF',
        borderPressed: '1px solid #EAEAEAFF',
        textColorTextPressed: '#EAEAEAFF',
    },
    common: {
        popoverColor: '#2C2828FF',
        textColor1: '#FFFAF2',
    },
    Input: {
        caretColor: '#222222FF',
        borderFocus: '1px solid #283252',
        border: '1px solid #283252',
        boxShadow: '1px solid #283252',
        boxShadowFocus: '1px solid #283252',
        rippleColor: "#283252",
        borderHover: '1px solid #283252',
        boxShadowHover: '1px solid #283252',
    },
    Card: {
        colorModal: "#26364DFF",
        borderColor: "#001F69FF",
        textColor: "#FFFAF2",
        color: "#454545FF",
        titleTextColor: "#FFFAF2",
    },
    Message: {
        textColorError: "#FFFAF2",
    },
}